import { useState, useEffect } from "react";

/**
 * @param {string} url
*/

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCont = new AbortController();

    fetch(url, { signal: abortCont.signal })
      .then((res) => {
        if (!res.ok) {
          throw Error("An Error Occurred");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        setIsPending(false);
        setError(null);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          return;
        } else {
          const message =
            err.message === "Failed to fetch"
              ? "Could Not Fetch The Data"
              : err.message;
          setError(message);
          setIsPending(false);
        }
      });

    return () => {
      abortCont.abort();
    };
  }, [url]);

  return { data, isPending, error };
};

export default useFetch;
